import React, { useEffect, useState } from "react";
import { Row, Col, Container, CustomInput, FormGroup } from "reactstrap";
import styled from "styled-components";

import { useThemeOption } from "@simplur/gatsby-theme-full-site/src/hooks";
import { unformatNumber } from "@simplur/gatsby-theme-full-site/src/utils/formatters";

import Layout from "@simplur/gatsby-theme-full-site/src/components/layout";
import SEO from "@simplur/gatsby-theme-full-site/src/components/seo";
import ProductList from "@simplur/gatsby-theme-full-site/src/components/ProductList";
import BreadCumps from "@simplur/gatsby-theme-full-site/src/components/BreadCumps";

const FilterTitle = styled.h5`
  border-bottom: 1px solid;
  font-weight: 600;
`;

const FilterItem = ({ id, name, label, onChange }) => {
  return (
    <CustomInput
      id={id}
      type="checkbox"
      name={name}
      label={label}
      onChange={onChange}
    />
  );
};

const priceRanges = [
  {
    label: "$0 - $499",
    condition: (price) => price >= 0 && price <= 499,
  },
  {
    label: "$500 - $999",
    condition: (price) => price >= 500 && price <= 999,
  },
  {
    label: "$1000 - $1499",
    condition: (price) => price >= 1000 && price <= 1499,
  },
  {
    label: "$1500 - $1999",
    condition: (price) => price >= 1500 && price <= 1999,
  },
  {
    label: "$2000 - $4999",
    condition: (price) => price > 2000 && price <= 4999,
  },
  {
    label: "$5000 - $9999",
    condition: (price) => price > 5000 && price <= 9999,
  },
];

const FilterSideBar = ({ products, categoryListForFilter, onFilterChange }) => {
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [priceFilter, setPriceFilter] = useState([]);

  useEffect(() => {
    let filteredProducts = products;
    if (categoriesFilter.length > 0) {
      const categorySlugs = categoriesFilter.reduce((acct, brandCat) => {
        // const catBrand = categoryListForFilter.find((cf) => cf.brand === brand);
        return [...acct, ...brandCat.subCategories.map((sc) => sc.slug)];
      }, []);

      filteredProducts = products.filter((p) =>
        p.productCategories.nodes.find((pc) => categorySlugs.includes(pc.slug))
      );
    }

    if (priceFilter.length > 0) {
      filteredProducts = filteredProducts.filter((p) => {
        const price = unformatNumber(p.price);
        return priceFilter.find((pf) => pf.condition(price));
      });
    }

    onFilterChange(filteredProducts);
  }, [categoriesFilter, priceFilter]);

  const onCategoryFilterChange = (e, brandCat) => {
    const filter = categoriesFilter.filter((c) => c.brand !== brandCat.brand);
    if (e.target.checked) {
      filter.push(brandCat);
    }

    setCategoriesFilter(filter);
  };

  const onPriceFilterChange = (e, priceRange) => {
    const filter = priceFilter.filter((c) => c.label !== priceRange.label);
    if (e.target.checked) {
      filter.push(priceRange);
    }

    setPriceFilter(filter);
  };

  return (
    <aside>
      <FilterTitle>Brands</FilterTitle>
      <FormGroup>
        {categoryListForFilter.map((fc) => (
          <FilterItem
            id={fc.brand}
            name="brand"
            label={fc.brand}
            onChange={(e) => onCategoryFilterChange(e, fc)}
          />
        ))}
      </FormGroup>

      <FilterTitle>Price</FilterTitle>
      <FormGroup>
        {priceRanges.map((pr) => (
          <FilterItem
            key={pr.label}
            id={pr.label}
            name="price"
            label={pr.label}
            onChange={(e) => onPriceFilterChange(e, pr)}
          />
        ))}
      </FormGroup>
    </aside>
  );
};

const ProductsArchive = ({ pageContext }) => {
  const { brandFullName } = useThemeOption();
  const {
    category,
    products,
    breadCrump,
    filterCategories,
    pagination,
  } = pageContext;

  const [filteredProducts, setFilteredProducs] = useState(products);

  const allMachineSlugs = [
    "basic-sewing-machines",
    "brother-scanncut-machines-brother-scanncut-machines",
    "sergers",
    "sewing-embroidery-machines",
    "sewing-quilting-machines-1",
    "coverstitch-machines",
    "multi-needle-machines",
    "quilting-machines",
    "longarm-quilting-machines",
  ];

  const showProductFilter =
    allMachineSlugs.includes(category.slug) &&
    filterCategories &&
    filterCategories.length > 0;

  return (
    <Layout>
      {category ? (
        <SEO title={category.name} json={category.seo} />
      ) : (
        <SEO title="Our Products" description={`Welcome to ${brandFullName}`} />
      )}

      <Container>
        <BreadCumps path={pagination.paginationPath} breadCrump={breadCrump} />
      </Container>
      <Container>
        <Row>
          {showProductFilter && (
            <Col md="2" lg="2">
              <FilterSideBar
                products={products}
                onFilterChange={setFilteredProducs}
                categoryListForFilter={filterCategories}
              />
            </Col>
          )}

          <Col md={showProductFilter ? "10" : "12"}>
            <Row md={showProductFilter ? "2" : "3"} lg={showProductFilter ? "4" : "5"} xl={showProductFilter ? "4" : "5"}>
              <ProductList
                products={filteredProducts}
                pagination={pagination}
                col={showProductFilter ? "4" : "3"}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ProductsArchive;
