import React from "react";
import { Link } from "gatsby";
import { Button } from "reactstrap";
import styled from "styled-components";

import {
  useAddToCart,
  useCart,
  useThemeOption,
} from "@simplur/gatsby-theme-full-site/src/hooks";

import CardModal from "@simplur/gatsby-theme-full-site/src/components/CardModal";
import ListSignup from "@simplur/gatsby-theme-full-site/src/components/ListSignup";
import { ShoppingCart } from "styled-icons/fa-solid/ShoppingCart";

const ViewDetailButton = ({ children, product }) => {
  const { singleProductBaseSlug } = useThemeOption();

  return (
    <div className="text-center mt-0">
      <Link
        to={`/${singleProductBaseSlug}/${product.slug}`}
        className="btn btn-cta btn-block"
      >
        {children}
      </Link>
    </div>
  );
};

const CartWhite = styled(ShoppingCart)`
  color: #fff;
  width: 1rem;
`;

const useProductInfo = (product) => {
  const { isProductExist } = useCart();

  const { ACFEcommerce } = product;
  const canSell =
    ACFEcommerce?.ecommerceAndShipping === "ecommerce" && product.price;

  const inStock = product.stockStatus === "IN_STOCK";

  let canAddToCart = false;
  if (product.type === "SIMPLE") {
    canAddToCart = !product.isManModelEnabled;
  } else if (product.type === "VARIABLE" || product.type === "BUNDLE") {
    canAddToCart = false;
  }

  const hasAddonProducts =
    product.ACFAddOnProduct && product.ACFAddOnProduct.products?.length;

  const isProductAddedToCart = isProductExist(product);

  return {
    inStock,
    hasAddonProducts,
    canSell,
    canAddToCart:
      !isProductAddedToCart &&
      canAddToCart &&
      canSell &&
      !hasAddonProducts &&
      inStock,
    isProductAddedToCart,
  };
};

const ProductCardFooter = ({ product }) => {
  const { addToCartHandler } = useAddToCart();

  const productInfo = useProductInfo(product);
  const { freeGiftWithSignup } = product.ACFEcommerce;

  // let hasAddonProducts = false;
  // const validateAddonProduct = (product) => {
  //   let isValid = false;
  //   const forceSelectionAddons = product.ACFAddOnProduct.products?.filter(
  //     (p) => p.forceSelection && p.selectProducts,
  //   );
  //   isValid = forceSelectionAddons.length === 0 ? true : false;
  //   return isValid;
  // };

  // const isValidAddonProduct = false;
  // if (product.ACFAddOnProduct?.products?.length) {
  //   hasAddonProducts = true;
  //   isValidAddonProduct = validateAddonProduct(product);
  // }

  let cardFooter;
  if (freeGiftWithSignup === "freeGift") {
    cardFooter = <ListSignup productName={product.name} />;
  } else if (!productInfo.canSell) {
    cardFooter = <CardModal product={product} />;
  } else if (productInfo.isProductAddedToCart) {
    cardFooter = (
      <div className="text-center mt-0">
        <Link to="/cart" className="btn btn-cta text-center border-cta">
          In Cart! View{" "}
          <span>
            <CartWhite />
          </span>{" "}
        </Link>
      </div>
    );
  } else if (productInfo.canAddToCart) {
    cardFooter = (
      <div className="text-center mt-0">
        <Button
          color="cta"
          className="text-center btn-block"
          onClick={() => addToCartHandler(product, 1)}
        >
          Add to Cart
        </Button>
      </div>
    );
  } else if (!productInfo.inStock) {
    cardFooter = (
      <ViewDetailButton product={product}>View Now</ViewDetailButton>
    );
  } else {
    cardFooter = (
      <ViewDetailButton product={product}>View Details</ViewDetailButton>
    );
  }

  return cardFooter;
};

export default ProductCardFooter;
