import React, { useMemo } from "react";
import { Row, Col } from "reactstrap";

import ProductCard from "../product-card";
import Pagination from "@simplur/gatsby-theme-full-site/src/components/pagination";
import { useWishList } from "@simplur/gatsby-theme-full-site/src/hooks";

const ProductList = ({ products, pagination, col = "3" }) => {
  useWishList(products);

  const productsToUse = useMemo(() => products.filter((p) => p), [products]);

  return (
    <>
      {productsToUse.map((p) => (
        <Col key={p.id} className="d-flex h-auto mb-4">
          <ProductCard product={p} />
        </Col>
      ))}

      {pagination && (
        <Pagination
          currentPageNumber={pagination.currentPageNumber}
          numPages={pagination.numPages}
          paginationPath={pagination.paginationPath}
        />
      )}
    </>
  );
};

export default ProductList;
